import React, { useState } from "react";
import {
  useIdentityContext,
  IdentityContextProvider,
} from "react-netlify-identity";
import { Helmet } from "react-helmet";

import PageTitle from "../components/pageTitle";

import { form } from "../styles/form.module.scss";
import { login, errpr } from "./login.module.scss";

const LoginForm = () => {
  const [error, setError] = useState(false);

  const login = (e) => {
    e.preventDefault();
    setError(true);
    return false;
  };

  return (
    <form className={form} onSubmit={login}>
      <input type="email" placeholder="Email" required />
      <input type="text" placeholder="Password" required />
      <button type="submit">Login</button>
      {error && <div className={error}>Invalid username or password</div>}
    </form>
  );
};

const Body = () => {
  const identity = useIdentityContext();
  const isLoggedIn = identity && identity.isLoggedIn;

  return (
    <div className={login}>
      {isLoggedIn && <h1>Welcome {identity}</h1>}
      {!isLoggedIn && <LoginForm />}
    </div>
  );
};

export default function Login() {
  return (
    <>
      <Helmet>
        <title>Fresh Brewed Design - Login</title>
      </Helmet>
      <PageTitle>Client Login</PageTitle>
      <IdentityContextProvider url="https://freshbrewed.design">
        <Body />
      </IdentityContextProvider>
    </>
  );
}
